<template>
  <a-layout-content>
    <a-input-search
      placeholder="输入搜索内容"
      enter-button
      @search="onSearch"
    />
    <a-list
      item-layout="vertical"
      :grid="{ column: 2, gutter: 16 }"
      :data-source="pagination.data"
    >
      <template #renderItem="item">
        <a-list-item>
          <a-skeleton :loading="loading" active avatar>
            <a-card v-if="hasOrders" :title="item.title">
              <template #extra>
                <a-button type="link" @click="onClickDetail(item)">
                  更多
                </a-button>
              </template>
              <template #avatar>
                <a-avatar :src="item.avatar" />
              </template>
              <template>
                <div>总计(不含增值税)：{{ item.total }}</div>
                <div>申请日期：{{ item.applyDate }}</div>
                <div>要求进货日期：{{ item.requireDate }}</div>
                <div>当前审批节点：{{ item.currentFlow }}</div>
                <a-tag :color="item.auditStatus | color('audit')">
                  {{ item.auditStatus | status('audit') }}
                </a-tag>
                <a-tag :color="item.assignStatus | color('assign')">
                  {{ item.assignStatus | status('assign') }}
                  <span v-if="item.assigner != null">
                    {{ `, 由${item.assigner}执行` }}
                  </span>
                </a-tag>
              </template>
            </a-card>
            <a-card v-else :title="item.title">
              <template #extra>
                <a-button type="link" @click="onClickDetail(item)">
                  更多
                </a-button>
              </template>
              <a-statistic
                title="总计(元)"
                :value="item.total"
                :precision="2"
              />
              <a-tag :color="item.status | color('bill')">
                {{ item.status | status('bill') }}
              </a-tag>
            </a-card>
          </a-skeleton>
        </a-list-item>
      </template>
    </a-list>
    <a-pagination
      v-model="pagination.currentPage"
      :pageSize.sync="pagination.pageSize"
      :total="pagination.total"
      show-less-items
      show-quick-jumper
      show-size-changer
      @change="getItems"
      @show-size-change="onSizeChange"
    />
  </a-layout-content>
</template>

<script>
export default {
  name: 'Index',
  props: {
    type: String,
    status: Number
  },
  computed: {
    hasOrders() {
      return this.type != 'bill';
    }
  },
  watch: {
    $route: {
      handler: async function(newValue, oldValue) {
        if (newValue != oldValue) {
          this.pagination.type = this.type;
          this.pagination.status = this.status;
          if (!newValue.path.includes('detail')) {
            await this.getItems();
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        data: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        type: undefined,
        status: undefined,
        title: undefined
      }
    };
  },
  methods: {
    async getItems() {
      try {
        this.loading = true;
        const { $http, pagination, hasOrders } = this;
        const params = {
          currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          type: pagination.type,
          status: pagination.status,
          title: pagination.title
        };
        const url = hasOrders ? '/orders' : '/bills';
        const { data, pageSize, total } = await $http.get(url, {
          params
        });
        pagination.data = data;
        Object.assign(this.pagination, { pageSize, total });
      } finally {
        this.loading = false;
      }
    },
    async onSizeChange(current, size) {
      this.pagination.currentPage = current;
      this.pagination.pageSize = size;
      await this.getItems();
    },
    async onClickDetail(item) {
      const prefix = this.$route.name;
      const name = `${prefix}/detail`;
      await this.$router.push({ name, params: { id: item.id } });
    },
    async onSearch(e) {
      this.pagination.title = e;
      await this.getItems();
    }
  }
};
</script>

<style lang="scss" scoped>
.ant-layout-content {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background-color: #fff;

  .ant-input-search {
    margin-bottom: 24px;
    width: 400px;
  }

  .ant-list {
    flex: 1 0;
    overflow: auto;
  }

  .ant-pagination {
    flex: 0 0;
    margin-top: 24px;
  }
}
</style>
